import React, { useState, useEffect } from "react";

function CmsTopArrow() {
  const [show, setShow] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {

    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("load", controlNavbar);
      window.addEventListener("scroll", controlNavbar);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [show]);

  return (
    <>
      <div onClick={scrollToTop}
        className={`back-to-top cursor-pointer fixed ${show ? 'flex' : 'hidden'} bottom-8 right-8 left-auto z-[999] h-10 w-10 items-center justify-center rounded-md bg-primary text-white shadow-md transition duration-300 ease-in-out hover:bg-dark`}
      >
        <span className="mt-[6px] h-3 w-3 rotate-45 border-t border-l border-white"></span>
      </div>
    </>
  );
}

export default CmsTopArrow;
