import React from "react";
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { sendMail } from "../lib/api-lib";

function CmsForm() {
  const [showModal, setShowModal] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showResult, setShowResult] = React.useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      mailto: process.env.MAIL_TO,
      name: event.target.fullName.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      subject: process.env.MAIL_SUBJECT,
      message: event.target.message.value,
    };

    setShowLoading(true);

    fetch(process.env.BASE_URL + "/api/sendmail", {
      body: JSON.stringify(data),
      method: "POST",
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      setShowLoading(false);
      if (data.response == 1) {
        setShowResult(1);
      } else {
        setShowResult(2);
      }
    })
    .catch((error) => {
      setShowLoading(false);
      setShowResult(2);
    });
  };

  const closeForm = () => {
    document.querySelector("body, html").classList.remove("no-scroll");
    // setShowModal(false);
    setShowResult(0);
  };

  return (
    <>
      {showModal ? (
        <div
          className="contact-form absolute top-0 right-0 rounded-lg bg-white py-10 px-8 shadow-lg sm:py-12 sm:px-10 md:p-[60px] lg:p-10 lg:py-12 lg:px-10 2xl:p-[60px] w-full z-40"
          data-wow-delay=".2s"
        >
          {showResult == 0 ? (
            <>
              {showLoading ? (
                <div className="absolute w-full h-full theme-b5 z-10 flex flex-col justify-center items-center">
                  <TailSpin
                    type="Puff"
                    color="#15A1b1"
                    height={70}
                    width={70}
                    timeout={3000}
                  />
                  <div>sending...</div>
                </div>
              ) : (
                ""
              )}
              <h3 className="mb-8 text-2xl font-semibold md:text-[26px]">
                Contattaci
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label htmlFor="fullName" className="block text-xs text-dark">
                    Nome e cognome*
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Nome Cognome"
                    className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="email" className="block text-xs text-dark">
                    Email*
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="example@yourmail.com"
                    className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="phone" className="block text-xs text-dark">
                    Telefono*
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="+39 321 65 49 879"
                    className="w-full border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none"
                    required
                    />
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="block text-xs text-dark">
                    Messaggio*
                  </label>
                  <textarea
                    name="message"
                    rows="1"
                    placeholder="scrivi qui il tuo messaggio"
                    className="w-full resize-none border-0 border-b border-[#f1f1f1] py-4 focus:border-primary focus:outline-none"
                    required
                  ></textarea>
                </div>
                <div className="mb-0">
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-dark"
                  >
                    Invia richiesta
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <div className="form-cms-container-inner">
                <div className="relative p-6 flex-auto">
                  <div className="z-10 flex flex-col justify-center items-center">
                    {showResult == 1 ? (
                      <>
                        <h5 className="theme-c1 text-center">
                          Grazie per averci contattato!
                        </h5>
                        <p className="font-normal text-center">
                          Ti risponderemo appena possibile
                        </p>
                        {/* <div className="mt-8 mb-12">
                      <button
                        className="sendBtn"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          closeForm();
                        }}
                      >
                        Chiudi
                      </button>
                    </div> */}
                      </>
                    ) : (
                      <>
                        <h5 className="text-red-600 text-center">
                          Si è verificato un errore!
                        </h5>
                        <p className="font-normal mt-8 text-center">
                          Ci scusiamo per l&apos;inconveniente.
                          <br />
                          Scrivici pure all&apos;indirizzo riportato di fianco
                        </p>
                        <div className="mt-8 mb-12">
                          <button
                            className="sendBtn"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              closeForm();
                            }}
                          >
                            Chiudi
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

export default CmsForm;
