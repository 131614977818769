import React, { useState, useEffect } from "react";
import Link from "next/link";

import Logo from "./Logo";
import { NavLinkMobile } from "./NavLinkMobile";
import Hamburger from "./Hamburger";

function NavBarMobile({ navigation }) {
  const [show, setShow] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    // document.body.classList.add("mobileMenuShow");

    const controlNavbar = () => {
      // console.log('controlNavbar');
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(false);
          document.body.classList.remove("mobileMenuShow");
        } else {
          setShow(true);
          document.body.classList.add("mobileMenuShow");
        }
        setLastScrollY(window.scrollY);

        if (window.scrollY > 0) {
          setIsTop(false);
          document.body.classList.remove("isTop");
        } else {
          setIsTop(true);
          document.body.classList.add("isTop");
        }
      }
    };

    if (typeof window !== "undefined") {
      // console.log(window);
      if (document.readyState === "complete") {
        controlNavbar();
      } else {
        window.addEventListener("load", controlNavbar);
      }
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY, isTop, show]);

  return (
    <>
      {/* <nav
        className={`mainNavMobile ${!show ? "navHidden" : ""} ${
          !isTop ? "floating" : ""
        }`}
      >
        <div className="flex h-full w-full justify-between items-center">
          <Link href="/">
            <a className="Logo">
              <Logo />
            </a>
          </Link>
        </div>
      </nav>
      <Hamburger />
      <div className="mobileCanvasOverlay"></div>
      <div className="mobileOffCanvas">
        <div className="mobileMenu">
          <div className="mobileMenuContainer">
            <div>
              {typeof navigation !== "undefined" && navigation.length > 0 ? (
                <ul className={`navBar`}>
                  {navigation.map(function (link, i) {
                    return (
                      <li key={i}>
                        <NavLinkMobile link={link} exact mobile={true} />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default NavBarMobile;
