import React, { useState, useEffect } from "react";
import Head from "next/head";
import CmsRow from "./CmsRow";
// import CmsFooter from "./CmsFooter";
import CmsTopArrow from "./CmsTopArrow";

import dynamic from "next/dynamic";

import Aos from "aos";
import "aos/dist/aos.css";

function CmsPage({ structure }) {
  const CmsMap = dynamic(() => import("./CmsMap"), {
    ssr: false,
  });

  useEffect(() => {
    if (document.readyState !== "loading") {
      /* HOMEPAGE */
      // applyAosToElement(".homepage #riga1 .checkContainer", "fade-left");
      // applyAosToElementByIndex(
      //   ".homepage #riga2 #card-group-1657726991 > div",
      //   0,
      //   "fade-right"
      // );
      // applyAosToElementByIndex(
      //   ".homepage #riga2 #card-group-1657726991 > div",
      //   1,
      //   "fade-up"
      // );
      // applyAosToElementByIndex(
      //   ".homepage #riga2 #card-group-1657726991 > div",
      //   2,
      //   "fade-left"
      // );
      // applyAosToElement(".homepage #riga3", "fade-up");
      // applyAosToElement(".homepage #riga4 .text", "fade-up");
      // applyAosToElement(".homepage #map-title .text", "fade-up");
      // applyAosToElement(".homepage #map-title .text", "fade-up");
      // applyAosToElement(".homepage .mapOverlayBox", "fade-right");

      // applyAosToElement("#home .text", "fade-up");
      // applyAosToElementAll(".text", "fade-up");



      Aos.init();
    }
  }, []);

  // const iubendaImplementation = `
  // <script type="text/javascript">
  // var _iub = _iub || [];
  // _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"floatingPreferencesButtonDisplay":"bottom-left","invalidateConsentWithoutLog":true,"perPurposeConsent":true,"siteId":2804046,"whitelabel":false,"cookiePolicyId":69938527,"lang":"it", "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#000000","closeButtonRejects":true,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","textColor":"#000000" }};
  // </script>
  // <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
  // `;

  return (
    <>
      <Head>
        <title>{structure.page.title}</title>
        <meta name="description" content={structure.page.description} />
      </Head>
      {/* <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div> */}
      <main
        className={`${structure.page.template} cmsPage`}
        id={`${structure.page.slug ?? ""}`}
      >
        {structure.rows.map(function (row, i) {
          if (row.id == "map-title") {
            return (
              <div key={i}>
                <CmsRow row={row} page_id={structure.page.id} />
                <CmsMap row={row} />
              </div>
            );
          } else {
            return <CmsRow row={row} page_id={structure.page.id} key={i} />;
          }
        })}
      </main>
      {/* <CmsFooter structure={structure} /> */}
      <CmsTopArrow />
    </>
  );
}

function applyAosToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos", effect);
  }
}

function applyAosToElementByIndex(selector, index, effect) {
  if (document.querySelectorAll(selector)[index]) {
    document.querySelectorAll(selector)[index].setAttribute("data-aos", effect);
  }
}

function applyAosToElementAll(selector, index, effect) {
  if (document.querySelectorAll(selector)) {
    [...document.querySelectorAll(selector)].forEach( div => {
      div.setAttribute("data-aos", effect);
    })
  }
}

export default CmsPage;
