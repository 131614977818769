import dynamic from "next/dynamic";

import CmsSocial from "./CmsSocial";

import CmsForm from "./CmsForm";
// import CmsSwiperIntro from "./CmsSwiperIntro";

const dynamicComponents = {
  // slideshow: dynamic(() => import("./CmsSlideshow")),
  text: dynamic(() => import("./CmsText")),
  image: dynamic(() => import("./CmsImage")),
  card: dynamic(() => import("./CmsCard")),
  cardGroup: dynamic(() => import("./CmsCardGroup")),
  swiper: dynamic(() => import("./CmsSwiper")),
  swiperIntro: dynamic(() => import("./CmsSwiperIntro")),
  video: dynamic(() => import("./CmsVideo")),
  attachment: dynamic(() => import("./CmsAttachment")),
  link: dynamic(() => import("./CmsButton")),
  collection: dynamic(() => import("./CmsCollection")),
  item: dynamic(() => import("./CmsCollectionItem")),
};

function CmsRow({ row, page_id = "" }) {
  return (
    <section id={`${row.id ?? ""}`}>
      <div className={row.full ? "container-xxl mx-auto" : `container mx-auto`}>
        <div
          className={
            row.columns != "full" && row.columns > 1
              ? `griglia grid-cols-${row.columns} gap-8`
              : ""
          }
        >
          {row.components.map(function (component, i) {
            component.name =
              component.name == "card-group" ? "cardGroup" : component.name;
            component.name =
              component.id == "introslide" ? "swiperIntro" : component.name;
            component.name =
              component.name == "collection" &&
              typeof component.options.items[0].gallery !== "undefined"
                ? "item"
                : component.name;

            const Component = dynamicComponents[component.name];

            component.options.columns =
              typeof component.options.columns != "undefined"
                ? component.options.columns
                : 1;

            if (typeof Component != "undefined") {
              return (
                <div
                  className={`col-span-${component.options.columns}`}
                  key={i}
                >
                  <Component component={component} page_id={page_id} />
                </div>
              );
            } else {
              return (
                <div key={i}>
                  {component.id == "contact-form" ? <CmsForm /> : ""}
                </div>
              );
            }
          })}
        </div>
      </div>
      {row.id == "plus" ? (
        <div className="absolute bottom-0 right-0 z-[-1]">
          <svg
            width="1440"
            height="886"
            viewBox="0 0 1440 886"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="1308.65"
                y1="1142.58"
                x2="602.827"
                y2="-418.681"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#3056D3" stopOpacity="0.36" />
                <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
                <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default CmsRow;
